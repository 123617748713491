import { useInstantSearchContext } from 'react-instantsearch';
import { useRouter } from 'next/router';
import { usePage } from '~/templates/pages';
import { useAlgoliaQuery } from '~/shared/hooks/useAlgoliaQuery';

export const colorSwatchKey = 'cust_swatchimages.swatchKey';

interface IColorSwatch {
    key: string;
    imageUrl: string;
}

type AlgoliaResult = {
    facets?: Record<string, Record<string, number>>;
};

function processFacetsData(result: AlgoliaResult) {
    const colorSwatches = result?.facets?.[colorSwatchKey];

    const keys = Object.keys(colorSwatches ?? {});

    return keys.length
        ? keys.map((item) => {
              const [key, ...imageUrlParts] = item.split('|');
              return { key, imageUrl: imageUrlParts.join('|') } as IColorSwatch;
          })
        : [];
}

interface IColorSwatch {
    key: string;
    imageUrl: string;
}

const useColorSwatchesFallback = () => {
    const page = usePage();
    const router = useRouter();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const category = page?.dataConfiguration || '';
    const query = router?.query?.query || '';

    const { data } = useAlgoliaQuery<any, IColorSwatch[]>(
        {
            ruleContexts: [category],
            facets: ['*'],
            hitsPerPage: 0,
        },
        {
            queryKey: [
                JSON.stringify({
                    ruleContexts: [category],
                    facets: ['*'],
                    hitsPerPage: 0,
                }),
            ],
            select: processFacetsData,
            enabled: !!category || !!query,
            gcTime: 1000 * 60 * 60,
        }
    );

    return data ?? [];
};

const useColorSwatches = () => {
    const instantSearchContext = useInstantSearchContext();
    const [results] = instantSearchContext?._initialResults?.facets?.results ?? [];
    const colorSwatches = results ? processFacetsData(results) : useColorSwatchesFallback();

    return { colorSwatches };
};

export default useColorSwatches;
