import styled from '@emotion/styled';
import { Accordion, StyledResetButton } from '~/shared/components';

export const StyledWrapper = styled(Accordion)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

export const StyledContent = styled.div(() => ({
    flex: '1',
    overflow: 'auto',
}));

export const StyledHeader = styled.header(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: `${theme.space[5]} ${theme.space[5]}`,
    boxShadow: theme.shadows.button,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.lg,
    fontWeight: theme.fontWeights.medium,
}));

export const StyledClose = styled(StyledResetButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.space[5],
    right: theme.space[4],
}));

export const StyledSelectedWrapper = styled.div(({ theme }) => ({
    padding: `${theme.space[4]} ${theme.space[4]} ${theme.space[3]}`,
    borderBottom: `1px solid ${theme.colors.brownLight}`,
}));

export const StyledCTA = styled.div(({ theme }) => ({
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.space[2]} `,
    padding: `${theme.space[5]} ${theme.space[4]}`,
    background: theme.colors.white,
}));
