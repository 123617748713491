import { FC } from 'react';
import { useToggleRefinement } from 'react-instantsearch';
import SwitchElement from '~/shared/components/form/components/controllable-elements/switch-element';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import IFilterRefinementSwitch from './FilterRefinementSwitch.definitions';

const FilterRefinementSwitch: FC<IFilterRefinementSwitch> = ({
    label,
    specificFilteringType,
    attribute,
    value: facetValue,
}) => {
    const { data: frame } = useFrame();
    const { filterEvent } = useEvents(frame);
    const { refine, value } = useToggleRefinement({
        attribute,
        on: facetValue,
    });

    return (
        <SwitchElement
            label={label}
            checked={value.isRefined}
            onChange={() => {
                if (refine) {
                    refine(value);
                    filterEvent(
                        `Filtering${specificFilteringType ? ` - ${specificFilteringType}` : ''}`,
                        label,
                    );
                }
            }}
        />
    );
};

export default FilterRefinementSwitch;
