import styled from '@emotion/styled';
import { Chevron } from '~/shared/components/Chevron/Chevron';

type AccordionHeadeProps = {
    hasSelected: boolean;
    hasFacetSelected: boolean;
};

export const StyledAccordion = styled.div(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.brownLight}`,
    overflow: 'hidden',
    transform: 'translateZ(0)', // Ensure chevron to follow transition on iOS
}));

export const StyledAccordionFacetContainer = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[1],
}));

export const StyledAccordionHeader = styled.h4<AccordionHeadeProps>(
    ({ theme, hasFacetSelected, hasSelected }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '14px',
        color: theme.colors.black,
        fontSize: theme.fontSizes.sm,
        fontWeight: hasSelected ? theme.fontWeights.bold : theme.fontWeights.regular,
        textTransform: 'inherit',
        backgroundColor: hasFacetSelected ? theme.colors.brownLight : theme.colors.white,
    }),
);

export const StyledChevron = styled(Chevron)(({ theme }) => ({
    boxSizing: 'border-box',
    padding: theme.space[1],
    width: 24,
    height: 24,
    color: theme.colors.brownMedium,
}));

export const StyledAccordionContent = styled.div(() => ({
    willChange: 'height',
    transition: 'height 0.3s ease-in-out',
    overflow: 'hidden',
}));
