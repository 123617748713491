import create from 'zustand';
type TotalHits = {
    totalHits: number;
    setTotalHits: (isLoaded: number) => void;
};

export const useGetTotalHits = create<TotalHits>((set) => ({
    totalHits: 0,
    setTotalHits: (totalHits) => set({ totalHits }),
}));
