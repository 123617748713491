import { DependencyList, useMemo } from 'react';

// TODO(IMC-514): Resolve function args to not use any[].
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionArgs = any[];

/**
 * This signature should match any and all functions with or without parameters
 * that does not return a value.
 */
type GenericFunction = (...args: FunctionArgs) => void;

export const deferFunction = (fn: GenericFunction) => {
    /**
     * We will "defer" the execution of the function that is passed by using
     * setTimeout(fn, 0). This will move our function out of the "event loop"
     * to prevent blocking the "stack" with slow code.
     *
     * Our code will only execute after the current stack is cleared along with
     * other tasks that has been queued. This is best used for slow render-blocking
     * synchronous actions.
     *
     * Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Event_loop
     */
    return (...args: FunctionArgs) => {
        setTimeout(() => fn(...args), 0);
    };
};

export const useDeferredFunction = (fn: GenericFunction, deps: DependencyList = [fn]) => {
    // NOTE: deps already have [fn] as the default trigger dependency but TS does not see this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const deferredFn = useMemo(() => deferFunction(fn), deps);

    return deferredFn;
};
