import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { algoliaClient, getAlgoliaIndex } from '~/lib/algolia';

type SearchQuery = {
    query?: string;
    filters?: string;
    ruleContexts?: string[];
    hitsPerPage?: number;
    facets?: string[];
};

const index = algoliaClient.initIndex(getAlgoliaIndex('MAIN'));

function search<T>({
    filters,
    query = '',
    ruleContexts = [],
    hitsPerPage,
    facets,
}: SearchQuery): Promise<T> {
    return index
        .search(query, {
            filters,
            ruleContexts: ruleContexts,
            hitsPerPage: hitsPerPage,
            analytics: true,
            facets,
        })
        .then((response) => (response as unknown) as T);
}

export function useAlgoliaQuery<T, Q>(query: SearchQuery, options: UseQueryOptions<T, unknown, Q>) {
    return useQuery<T, unknown, Q>({
        ...options,
        queryKey: [JSON.stringify(query)],
        queryFn: () => search<T>(query),
    });
}
