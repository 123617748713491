import styled from '@emotion/styled';

export const StyledWrapper = styled.label({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    cursor: 'pointer',
});

export const StyledFieldset = styled.fieldset({});

export const StyledInput = styled.input(({ theme }) => ({
    display: 'none',

    [`&:checked ~ ${StyledSwitchIndicator}`]: {
        background: theme.colors.black,

        '&::after': {
            left: `calc(100% - ${switchTriggerSize}px - 1px)`,
        },
    },

    [`&:disabled ~ ${StyledSwitchIndicator}`]: {
        background: theme.colors.grey30,

        '&::after': {
            opacity: '0.8',
        },
    },
}));

export const StyledLegend = styled.legend<{ valid: boolean; disabled?: boolean }>(
    ({ theme, valid, disabled }) => ({
        display: 'block',
        marginRight: theme.space[3],
        cursor: 'pointer',
        color: valid
            ? disabled
                ? theme.colors.grey30
                : theme.colors.black
            : theme.colors.negative,
        ...theme.mixins.useTextStyle('bodySmall'),
    }),
);

const switchTriggerSize = 22;
export const StyledSwitchIndicator = styled.span(({ theme }) => ({
    display: 'block',
    position: 'relative',
    width: 45,
    height: switchTriggerSize + 2,
    overflow: 'hidden',
    borderRadius: switchTriggerSize,
    pointerEvents: 'none',
    background: theme.colors.brownLight,
    transition: 'background 0.1s',

    '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: 0,
        top: 1,
        left: 1,
        width: switchTriggerSize,
        height: switchTriggerSize,
        borderRadius: switchTriggerSize,
        overflow: 'hidden',
        background: theme.colors.white,
        transition: 'left 0.1s linear',
    },
}));

export const StyledSwitchHelpTexts = styled.div(({ theme }) => ({
    marginLeft: `calc(25px + ${theme.space[3]})`,
}));
