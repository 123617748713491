import styled from '@emotion/styled';
import { breakpoints } from '~/theme/breakpoints';

export const StyledWrapper = styled.div(({ theme }) => ({
    marginTop: theme.space[4],
    marginBottom: theme.space[3],
    padding: `0 ${theme.space[3]}`,

    [breakpoints.md]: {
        padding: `0 ${theme.space[6]}`,
    },
}));

export const StyledCount = styled.span<{ isVisible: boolean }>(({ theme, isVisible }) => ({
    color: theme.colors.black,
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fonts.regular,
    fontWeight: theme.fontWeights.medium,
    visibility: isVisible ? 'visible' : 'hidden',
}));
