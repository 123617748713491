import React, { FC, forwardRef, memo, useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import RefinementList from '../RefinementList/RefinementList';
import RefinementListStock from '../RefinementList/RefinementListStock';
import RefinementListToggle from '../RefinementList/RefinementListToggles';
import IRefinementAccordion from './RefinementAccordion.definitions';
import { preDefinedAttributes } from '../FilterDrawer';
import {
    StyledAccordion,
    StyledAccordionContent,
    StyledAccordionFacetContainer,
    StyledAccordionHeader,
    StyledChevron,
} from './RefinementAccordion.styled';
import RefinementListColor from '../RefinementList/RefinementListColor';
import { colorSwatchKey } from '~/features/productList/hooks/useColorSwatches';
import { TranslationKey } from '~/lib';
import { useTranslation } from '~/shared/utils/translation';
import { useCurrentRefinements } from 'react-instantsearch';
import { RefinementPrice } from '../../FilterList/components/RefinementPrice';

const RefinementAccordion: FC<IRefinementAccordion> = forwardRef<
    HTMLDivElement,
    IRefinementAccordion
>(({ attribute, isParentOpen, handleOpenToggle }, accordionRef) => {
    const { translate } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [contentContainerRef, { height: contentContainerHeight }] = useMeasure<HTMLDivElement>();
    const { items: currentRefinements } = useCurrentRefinements();

    const attributeLabel = useMemo(() => {
        if (attribute === preDefinedAttributes.newsToggles) {
            return {
                label: translate('plp.facet.campaigns'),
                attribute: ['cust_onOffer.isOnOffer', 'cust_onOffer.isMemberOffer'],
            };
        }

        if (attribute === preDefinedAttributes.stockStatus) {
            return {
                label: translate('plp.facet.cust_storeIdsWithStock'),
                attribute: ['cust_storeNamesWithStock', 'cust_in_stock_web'],
            };
        }

        if (['cust_sole_representation', 'cuse_isNew', 'cust_priceRanges'].includes(attribute)) {
            return {
                label: translate(`plp.facet.${attribute}` as TranslationKey),
                attribute,
            };
        }

        return {
            label: translate(`plp.facet.${attribute}` as TranslationKey),
            attribute,
        };
    }, [attribute, translate]);

    const attributeOptions = useMemo(() => {
        switch (attribute) {
            case preDefinedAttributes.newsToggles:
                return (
                    <RefinementListToggle
                        attributes={[
                            { attribute: 'cust_onOffer.isOnOffer', on: true },
                            { attribute: 'cust_onOffer.isMemberOffer', on: true },
                            // { attribute: 'cust_activepromo.promotiontype', on: 'Julepris' },
                        ]}
                    />
                );

            case colorSwatchKey:
                return null;

            case preDefinedAttributes.stockStatus:
                return <RefinementListStock />;

            case 'cust_sole_representation':
            case 'cust_isNew':
            case 'cust_spec_allergyFriendly':
            case 'cust_spec_personalization':
                return <RefinementListToggle attributes={[{ attribute, on: true }]} />;

            case 'cust_priceRanges':
                return (
                    <>
                        <RefinementPrice attribute="cust_effectivePrice" />
                        <RefinementList showCount attribute={attribute} />
                    </>
                );

            case 'cust_color':
                return <RefinementListColor attribute={attribute} searchable showCount />;

            default:
                return <RefinementList attribute={attribute} searchable showCount />;
        }
    }, [attribute]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
        handleOpenToggle?.(attribute, !isOpen);
    };

    useEffect(() => {
        if (!isParentOpen) setIsOpen(false);
    }, [isParentOpen]);

    const tilbudFacets = ['cust_onOffer.isMemberOffer', 'cust_onOffer.isOnOffer'];
    const storeFacets = ['cust_in_stock_web', 'cust_storeNamesWithStock'];

    const selectedFacetsTilbud = useMemo(
        () =>
            currentRefinements.map((item) =>
                tilbudFacets.includes(item.attribute) ? item.attribute : '',
            ),
        [currentRefinements, tilbudFacets],
    );

    const selectedStoreFacets = useMemo(
        () =>
            currentRefinements.map((item) =>
                storeFacets.includes(item.attribute) ? item.attribute : '',
            ),
        [currentRefinements, storeFacets],
    );

    const selectedFacets = useMemo(
        () => currentRefinements.find((item) => attributeLabel.attribute.includes(item.attribute)),
        [currentRefinements, attributeLabel],
    );

    const arraysMatch = (array1: string[], array2: string[]): boolean => {
        return array2.every((value) => array1.includes(value) && array2.includes(value));
    };

    const storeCount = useMemo(
        () => currentRefinements.find((item) => item.attribute === 'cust_storeNamesWithStock'),
        [currentRefinements],
    );

    const selectedFacetsCount = useMemo(() => {
        if (selectedFacets && attributeLabel.attribute.includes(selectedFacets?.attribute)) {
            if (
                storeCount &&
                arraysMatch(selectedStoreFacets, storeFacets) &&
                (selectedFacets.attribute.includes('cust_in_stock_web') ||
                    selectedFacets.attribute.includes('cust_storeNamesWithStock'))
            ) {
                return `(${translate('plp.selectedFacets', {
                    count: `${storeCount?.refinements.length + 1}`,
                })})`;
            }
            if (
                arraysMatch(selectedFacetsTilbud, tilbudFacets) &&
                (selectedFacets.attribute.includes('cust_onOffer.isMemberOffer') ||
                    selectedFacets.attribute.includes('cust_onOffer.isOnOffer'))
            ) {
                return `(${translate('plp.selectedFacets', {
                    count: `2`,
                })})`;
            } else {
                return `(${translate('plp.selectedFacets', {
                    count: selectedFacets?.refinements.length.toString(),
                })})`;
            }
        } else {
            return null;
        }
    }, [
        selectedFacets,
        attributeLabel,
        tilbudFacets,
        storeFacets,
        selectedFacetsTilbud,
        selectedStoreFacets,
        storeCount,
    ]);

    return (
        <StyledAccordion ref={accordionRef}>
            <StyledAccordionHeader
                hasFacetSelected={
                    currentRefinements.some((item) =>
                        attributeLabel.attribute.includes(item.attribute),
                    ) && !isOpen
                }
                hasSelected={isOpen}
                onClick={handleToggle}
            >
                {attributeLabel.label}

                <StyledAccordionFacetContainer>
                    {!isOpen ? selectedFacetsCount : null}
                    <StyledChevron isOpen={isOpen} />
                </StyledAccordionFacetContainer>
            </StyledAccordionHeader>

            <StyledAccordionContent style={{ height: isOpen ? contentContainerHeight : 0 }}>
                <div ref={contentContainerRef}>{attributeOptions}</div>
            </StyledAccordionContent>
        </StyledAccordion>
    );
});

RefinementAccordion.displayName = 'RefinementAccordion';

export default memo(RefinementAccordion);
