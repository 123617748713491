import React, { createRef, FC, RefObject, useCallback, useMemo, useRef } from 'react';
import { useClearRefinements, useDynamicWidgets } from 'react-instantsearch';
import CloseIcon from '~/icons/close.svg';
import { Button } from '~/shared/components';
import { useTranslation } from '~/shared/utils/translation';
import { Drawer } from '~/shared/components/Drawer';
import { useFilterState } from '../../hooks/useFilterState';
import { useStats } from '../../hooks/useStats';
import Trigger from './Trigger/Trigger';
import RefinementAccordion from './RefinementAccordion/RefinementAccordion';
import {
    StyledClose,
    StyledContent,
    StyledCTA,
    StyledHeader,
    StyledWrapper,
} from './FilterDrawer.styled';
import { useDeferredFunction } from '~/shared/hooks/useDeferredFunction';
import { theme } from '~/theme';

export const preDefinedAttributes = {
    stockStatus: 'preDefinedStockStatus',
    newsToggles: 'preDefinedNewsToggles',
};

const FilterDrawer: FC = () => {
    const { translate } = useTranslation();
    const { nbHits } = useStats();
    const {
        isOpen,
        actions: { close, toggle },
    } = useFilterState();
    const { refine } = useClearRefinements();
    const scrollableRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLElement>(null);

    const handleDrawerToggle = useCallback(() => {
        if (isOpen && scrollableRef?.current) {
            scrollableRef.current.scrollTo(0, 0);
        }

        toggle();
    }, [isOpen]);

    const { attributesToRender: attributesDynamic } = useDynamicWidgets({
        maxValuesPerFacet: 1000,
        facets: ['*'],
    });

    const attributesToRender = useMemo(
        () => [
            preDefinedAttributes.stockStatus,
            preDefinedAttributes.newsToggles,
            ...attributesDynamic,
        ],
        [attributesDynamic],
    );

    const attributeRefs = useRef(
        attributesToRender.reduce(
            (acc, attribute) => ({
                ...acc,
                [attribute]: createRef<HTMLDivElement>(),
            }),
            {} as { [key: string]: RefObject<HTMLDivElement> },
        ),
    );

    const clearRefinements = useDeferredFunction(refine, []);

    const handleAttributeToggle = useCallback((attribute: string, attributeIsOpen: boolean) => {
        if (!attributeIsOpen) return;

        const targetRef = attributeRefs?.current[attribute]?.current;

        if (scrollableRef?.current && targetRef) {
            const headerHeight = headerRef?.current?.clientHeight || 0;

            scrollableRef.current.scrollTo({
                top: targetRef.offsetTop - headerHeight,
                behavior: 'smooth',
            });
        }
    }, []);

    return (
        <>
            <Trigger label={translate('plp.filterLabel')} handleToggle={handleDrawerToggle} />

            <Drawer open={isOpen} onClose={close}>
                <StyledWrapper>
                    <StyledHeader ref={headerRef}>
                        <span>{translate('plp.filterLabel')}</span>
                        <StyledClose onClick={close}>
                            <CloseIcon />
                        </StyledClose>
                    </StyledHeader>

                    <StyledContent ref={scrollableRef}>
                        {attributesToRender.map((attribute) => (
                            <RefinementAccordion
                                ref={attributeRefs.current[attribute]}
                                key={attribute}
                                attribute={attribute}
                                handleOpenToggle={handleAttributeToggle}
                                isParentOpen={isOpen}
                            />
                        ))}
                    </StyledContent>

                    <StyledCTA>
                        <Button fullWidth onClick={close}>
                            {translate('plp.confirmFilter')} ({nbHits})
                        </Button>

                        <Button
                            fullWidth
                            style={{
                                backgroundColor: theme.colors.white,
                                color: theme.colors.black,
                                border: `1px solid ${theme.colors.black}`,
                            }}
                            onClick={clearRefinements}
                        >
                            {translate('plp.clearFilter')}
                        </Button>
                    </StyledCTA>
                </StyledWrapper>
            </Drawer>
        </>
    );
};

export default FilterDrawer;
