import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-use';
import create from 'zustand';
import { CustomerProductListResult } from '~/lib/data-contract/salesforce';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { AuthTypes } from './useCustomer';
import useCustomerProductLists from './useCustomerProductLists';
import { useCustomerStore } from './useCustomerStore';

const DEFAULT_WISHLIST_NAME = 'imerco';
export const DEFAULT_WISHLIST_TYPE = 'shopping_list';
const queryKey = [DEFAULT_WISHLIST_NAME, DEFAULT_WISHLIST_TYPE];

const useWishlistStore = create<{ isInit: boolean; setInit: (isInit: boolean) => void }>((set) => {
    return {
        isInit: false,
        setInit: (isInit: boolean) => {
            set({ isInit: isInit });
        },
    };
});

export const useWishlist = () => {
    const queryClient = useQueryClient();
    const { isInit, setInit } = useWishlistStore();
    const {
        getOrCreateList,
        createListItem,
        getLists: fetchLists,
        removeListItem,
    } = useCustomerProductLists();
    const { customer } = useCustomerStore((state) => ({
        customer: state.customer,
    }));
    const { data: frame } = useFrame();
    const { wishlistEvent } = useEvents(frame as any);
    const location = useLocation();

    const getLists = () => queryClient.getQueryData(queryKey) as CustomerProductListResult;
    const { data, refetch } = useQuery({ queryKey, queryFn: fetchLists, enabled: !!getLists() });

    useEffect(() => {
        if (data) {
            return;
        }
        if (customer && !isInit) {
            if (!getLists() && customer.authType === AuthTypes.GUEST) {
                init();
            }

            if (!getLists() && customer.authType === AuthTypes.REGISTERED) {
                updateLists();
            }
            setInit(true);
        }
    }, [customer]);

    const findListByType = (type: string = DEFAULT_WISHLIST_TYPE) => {
        const lists = getLists();
        if (lists && lists.data) {
            return lists.data.find((list) => list.type === type);
        }

        return undefined;
    };

    const init = async () => {
        await getOrCreateList(DEFAULT_WISHLIST_NAME, DEFAULT_WISHLIST_TYPE);
        await refetch();
    };

    const updateLists = async () => {
        const currentdefaultList = findListByType();
        await init();
        if (currentdefaultList) {
            currentdefaultList?.customerProductListItems?.forEach(async (item) => {
                if (item.productId) {
                    await addItemToList(item.productId);
                }
                return;
            });
        }
    };

    const addItemToList = async (productId: string) => {
        const list = findListByType();

        if (list) {
            try {
                await createListItem(list?.id, { id: productId });
                wishlistEvent('Product added to wishlist (success)', `${list.id} | ${productId}`);
                await refetch();
            } catch {
                wishlistEvent('Product added to wishlist (error)', location.pathname);
            }
        }
    };

    const removeItemFromList = async (productId: string) => {
        const product = getProductFromList(productId, DEFAULT_WISHLIST_TYPE);
        const list = findListByType();
        if (list && product) {
            try {
                await removeListItem(list.id, product.id);
                wishlistEvent(
                    'Product removed from wishlist (success)',
                    `${list.id} | ${product.id || ''}`,
                );
                await refetch();
            } catch {
                wishlistEvent('Product removed from wishlist (error)', location.pathname);
            }
        }
    };

    const getProductFromList = (productId: string, listName: string = DEFAULT_WISHLIST_TYPE) => {
        const list = findListByType(listName);
        if (list) {
            return list.customerProductListItems?.find(
                (product) => product.productId === productId,
            );
        }
        return undefined;
    };

    const isFavorite = useCallback(
        (productId: string) => {
            return getProductFromList(productId, DEFAULT_WISHLIST_TYPE) !== undefined;
        },
        [data],
    );

    const handleFavourite = useCallback(
        async (id: string) => {
            if (isFavorite(id)) {
                removeItemFromList(id);
            } else {
                addItemToList(id);
            }
        },
        [isFavorite],
    );

    return {
        init,
        items: findListByType()?.customerProductListItems ?? [],
        list: findListByType(),
        addItemToList,
        removeItemFromList,
        handleFavourite,
        isFavorite,
        updateLists,
        count: findListByType()?.customerProductListItems?.length ?? 0,
    };
};
