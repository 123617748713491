import create from 'zustand';

type FilterState = {
    // The current open state
    isOpen: boolean;

    actions: {
        setIsOpen: (isOpen: boolean) => void;
        close: () => void;
        open: () => void;
        toggle: () => void;
    };
    // Specify the open state
};

export const useFilterState = create<FilterState>((set, get) => ({
    isOpen: false,
    actions: {
        setIsOpen: (isOpen) => set({ isOpen }),
        close: () => get().actions.setIsOpen(false),
        open: () => get().actions.setIsOpen(true),
        toggle: () => {
            const {
                isOpen,
                actions: { setIsOpen },
            } = get();
            setIsOpen(!isOpen);
        },
    },
}));
