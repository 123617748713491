import React, { FC, memo } from 'react';
import { useToggleRefinement } from 'react-instantsearch';
import { TranslationKey } from '~/lib';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import { IRefinementListToggles, RefinementToggleAttribute } from './RefinementList.definitions';
import {
    StyledCheckmark,
    StyledCheckmarkContainer,
    StyledContainer,
    StyledList,
    StyledListItem,
    StyledListTextWrapper,
    StyledListValueWrapper,
} from './RefinementList.styled';

interface RefinementListToggleItem {
    attribute: RefinementToggleAttribute;
}

const RefinementListToggleItem: FC<RefinementListToggleItem> = ({ attribute }) => {
    const { translate } = useTranslation();
    const { data: frameData } = useFrame();
    const { filterEvent } = useEvents(frameData);
    const { refine, value } = useToggleRefinement(attribute);

    const translationKey = (attribute.legend ||
        `plp.facet.${attribute.attribute}`) as TranslationKey;
    const legend = translate(translationKey);

    const handleRefine = () => {
        if (refine) {
            refine(value);

            filterEvent('Filtering', legend);
        }
    };

    return (
        <StyledListItem
            key={`${legend}-${value}`}
            isRefined={value.isRefined}
            onClick={handleRefine}
        >
            <StyledListTextWrapper>{legend}</StyledListTextWrapper>
            <StyledListValueWrapper>
                {value.count ? ` (${value.count})` : ''}
                <StyledCheckmarkContainer isRefined={value.isRefined}>
                    {value.isRefined && <StyledCheckmark isRefined={value.isRefined} />}
                </StyledCheckmarkContainer>
            </StyledListValueWrapper>
        </StyledListItem>
    );
};

const RefinementListToggle: FC<IRefinementListToggles> = ({ attributes }) => (
    <StyledContainer>
        <StyledList column>
            {attributes.map((attribute, index) => (
                <RefinementListToggleItem
                    key={`${attribute.attribute}}-${index}`}
                    attribute={attribute}
                />
            ))}
        </StyledList>
    </StyledContainer>
);

export default memo(RefinementListToggle);
