import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSortBy } from 'react-instantsearch';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import { AlgoliaSortOption, useAlgoliaSortOptions } from '../../../../lib/algolia';
import {
    StyledContainer,
    StyledTrigger,
    StyledChevron,
    StyledDropdown,
    StyledOption,
    StyledCheckmark,
} from './SortBy.styled';
import { useDeferredFunction } from '~/shared/hooks/useDeferredFunction';

const SortBy: FC = () => {
    const { translate } = useTranslation();
    const { data: frameData } = useFrame();
    const { filterEvent } = useEvents(frameData);
    const algoliaSortOptions = useAlgoliaSortOptions();
    const { currentRefinement, options, refine } = useSortBy({ items: algoliaSortOptions });
    const ref = useRef<HTMLDivElement>(null);

    const openDropdown = (open: boolean = true) => {
        ref.current && ref.current.classList[open ? 'add' : 'remove']('open');
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                openDropdown(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const deferredRefine = useDeferredFunction(refine);

    const handleChange = useCallback(
        (selected: AlgoliaSortOption) => {
            deferredRefine(selected.value);
            filterEvent('Sorting', translate(selected.label));
            openDropdown(false);
        },
        [deferredRefine, filterEvent, translate],
    );

    const dropdownOptions = useMemo(
        () =>
            options.map((item) => {
                const itemTyped = item as AlgoliaSortOption;
                const isSelected = itemTyped.value === currentRefinement;

                return (
                    <StyledOption
                        key={`${item.label}-${item.value}`}
                        isSelected={isSelected}
                        onClick={() => handleChange(itemTyped)}
                    >
                        <span>{translate(itemTyped.label)}</span>
                        {<StyledCheckmark isSelected={isSelected} />}
                    </StyledOption>
                );
            }),
        [currentRefinement, options, handleChange, translate],
    );

    // TODO(IMC-514): Resolve "as AlgoliaSortOption" assertions.
    const selectedItemLabel = useMemo(() => {
        const matchedOption = options.find(
            (item) => item.value === currentRefinement,
        ) as AlgoliaSortOption;

        const selectedOption = (matchedOption || options[0]) as AlgoliaSortOption;

        return selectedOption?.label;
    }, [currentRefinement, options]);

    return (
        <StyledContainer ref={ref}>
            <StyledTrigger onClick={() => openDropdown()}>
                {translate(selectedItemLabel)}
                <StyledChevron />
            </StyledTrigger>

            <StyledDropdown>{dropdownOptions}</StyledDropdown>
        </StyledContainer>
    );
};

export default memo(SortBy);
