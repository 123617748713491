import styled from '@emotion/styled';
import { mq } from '~/lib';
import { breakpoints } from '~/theme/breakpoints';

export const StyledWrapper = styled.div(({ theme }) => ({
    marginTop: theme.space[4],
    paddingLeft: theme.space[4],
    zIndex: 1,
    maxWidth: theme.sizes.contentMaxWidth,
    [mq(0, 'sm')]: {
        paddingLeft: theme.space[3],
    },
    [breakpoints.md]: {
        display: 'none',
    },
}));
