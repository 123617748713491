import styled from '@emotion/styled';
import Checkmark from '~/icons/checkmark.svg';
import { StyledResetButton } from '~/shared/components';
import { Chevron } from '~/shared/components/Chevron/Chevron';

export const StyledContainer = styled.div(({ theme }) => ({
    position: 'relative',
    '&.open': {
        [`${StyledChevron}`]: {
            transform: `rotateX(180deg)`,
        },
        [`${StyledDropdown}`]: {
            top: `calc(100% + ${theme.space[3]})`,
            boxShadow: theme.shadows.floating,
            visibility: 'visible',
            opacity: 1,
            transition: 'opacity 0.1s, top 0.2s, box-shadow 0.2s, visibility 0s',
        },
    },
}));

export const StyledTrigger = styled(StyledResetButton)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.black,
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fonts.regular,
}));

export const StyledChevron = styled(Chevron)(({ theme }) => ({
    marginLeft: theme.space[2],
    padding: theme.space[1],
    width: 22,
    height: 22,
    color: theme.colors.brownMedium,
}));

export const StyledDropdown = styled.div(({ theme }) => ({
    position: 'absolute',
    right: 0,
    top: `calc(100% + ${theme.space[2]})`,
    zIndex: theme.zIndices['1'],
    width: 225,
    maxHeight: 450,
    border: `1px solid ${theme.colors.brownUltraLight}`,
    borderRadius: theme.sizes.cornerRadius,
    visibility: 'hidden',
    opacity: 0,
    background: theme.colors.white,
    transition: 'opacity 0.1s 0.1s, top 0.2s, box-shadow 0.2s, visibility 0.2s',
}));

export const StyledOption = styled.div<{ isSelected: boolean }>(({ isSelected, theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.space[3],
    background: isSelected ? theme.colors.brownUltraLight : theme.colors.white,
    fontWeight: isSelected ? theme.fontWeights.medium : theme.fontWeights.regular,
    cursor: 'pointer',
    whiteSpace: 'nowrap',

    '&:hover': {
        background: theme.colors.grey10,
    },
}));

export const StyledCheckmark = styled(Checkmark)<{ isSelected: boolean }>(
    ({ isSelected, theme }) => ({
        flex: '0 0 19px',
        marginLeft: theme.space[4],
        width: 10,
        height: 10,
        opacity: isSelected ? 1 : 0,
        color: theme.colors.black,
    }),
);
