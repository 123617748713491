import React, { InputHTMLAttributes, useImperativeHandle, useRef } from 'react';
import { useInputField } from '../../../../../utils';
import { controllableInputElementsProps } from '../../../index';
import { StyledInvalidMessage } from '../../invalid-message';
import { StyledHelpText } from '../../help-text';
import {
    StyledWrapper,
    StyledFieldset,
    StyledInput,
    StyledSwitchIndicator,
    StyledLegend,
} from './switch-element.styles';

export interface SwitchElementProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'placeholder'>,
        controllableInputElementsProps {
    hideInvalidMessage?: boolean;
    title?: string;
}

export const SwitchElement = React.forwardRef<HTMLInputElement, SwitchElementProps>(
    (
        { label, helpText, title, isInvalid, invalidMessage, id, hideInvalidMessage, ...rest },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);
        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        const { fieldId, helpTextId, describedById, showHelpText } = useInputField({
            id,
            helpText,
            isInvalid,
            invalidMessage,
        });

        const showInvalidMessage = invalidMessage && !hideInvalidMessage;

        return (
            <StyledFieldset disabled={rest.disabled}>
                <StyledWrapper key={fieldId} tabIndex={0}>
                    {label && (
                        <StyledLegend
                            title={title}
                            valid={!isInvalid}
                            disabled={rest.disabled}
                            children={label}
                        />
                    )}

                    <StyledInput
                        {...rest}
                        type="checkbox"
                        id={fieldId}
                        ref={inputRef}
                        aria-describedby={describedById}
                    />

                    <StyledSwitchIndicator />
                </StyledWrapper>

                {showInvalidMessage && <StyledInvalidMessage children={invalidMessage} />}
                {showHelpText && <StyledHelpText id={helpTextId} children={helpText} />}
            </StyledFieldset>
        );
    }
);

export default SwitchElement;
