import React, { FC } from 'react';
import { useTranslation } from '~/shared/utils/translation';
import { DropDownItemButton, DropdownItemContainer, DropdownItemText } from './styled';

type Props = {
    label?: string;
};

export const RefinementEmptyList: FC<Props> = (props) => {
    const { translate } = useTranslation();
    return (
        <DropdownItemContainer grow>
            <DropDownItemButton fullWidth variant="plain" isRefined={false} disabled>
                <DropdownItemText>
                    <b>{props.label || translate('plp.noMatchFound')}</b>
                </DropdownItemText>
            </DropDownItemButton>
        </DropdownItemContainer>
    );
};

export default RefinementEmptyList;
