import React, { FC, memo, useCallback, useState } from 'react';
import { useEvents } from '~/shared/hooks/useEvents';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import FilterRefinementSwitch from '../../FilterRefinementSwitch/FilterRefinementSwitch';
import {
    StyledCheckmark,
    StyledCheckmarkContainer,
    StyledContainer,
    StyledList,
    StyledListItem,
    StyledListItemLogo,
    StyledListTextContainer,
    StyledListTextWrapper,
    StyledListValueWrapper,
    StyledOnlineToggle,
    StyleListInput,
} from './RefinementList.styled';
import { useDebounce } from 'react-use';
import { RefinementListItem } from '~/features/productList/types';
import useOptimizedRefinementList from '~/shared/hooks/useOptimizedRefinementList';
import RefinementEmptyList from '../../FilterList/components/RefinementEmptyList';
import { useCurrentRefinements } from 'react-instantsearch';

interface RefinementListItemsProps {
    items: RefinementListItem[];
    onRefine(value: string): void;
}

const RefinementListItems: FC<RefinementListItemsProps> = ({ items, onRefine }) => (
    <StyledList column>
        {items.map((item) => (
            <StyledListItem
                key={`${item.label}-${item.value}`}
                isRefined={item.isRefined}
                onClick={() => onRefine(item.value)}
            >
                <StyledListTextContainer>
                    <StyledListItemLogo />
                    <StyledListTextWrapper>{item.label}</StyledListTextWrapper>
                </StyledListTextContainer>

                <StyledListValueWrapper>
                    <StyledCheckmarkContainer isRefined={item.isRefined}>
                        {item.isRefined && <StyledCheckmark isRefined={item.isRefined} />}
                    </StyledCheckmarkContainer>
                </StyledListValueWrapper>
            </StyledListItem>
        ))}
    </StyledList>
);

const MemoizedRefinementListItems = memo(RefinementListItems);

const RefinementListStock: FC = () => {
    const { items: currentRefinements } = useCurrentRefinements();
    const { data: frame } = useFrame();
    const { translate } = useTranslation();
    const { filterEvent } = useEvents(frame);
    const [searchValue, setSearchValue] = useState('');

    const { refine, searchForItems, items } = useOptimizedRefinementList({
        limit: 1000,
        attribute: 'cust_storeNamesWithStock',
        escapeFacetValues: false,
    });

    const handleRefine = useCallback((value: string) => {
        if (!value) {
            return;
        }

        filterEvent('Filtering', value.toString());
        refine(value);
        setSearchValue('');
    }, []);

    useDebounce(
        () => {
            searchForItems(searchValue ?? '');
        },
        200,
        [searchValue],
    );

    return (
        <>
            <StyledOnlineToggle
                isRefined={currentRefinements.some((item) =>
                    item.attribute.includes('cust_in_stock_web'),
                )}
            >
                <FilterRefinementSwitch
                    attribute="cust_in_stock_web"
                    label={translate('plp.inStock')}
                    specificFilteringType="Online"
                    value={true}
                />
            </StyledOnlineToggle>

            <StyledContainer>
                <StyleListInput
                    isEmpty={items && items.length === 0}
                    placeholder={translate('plp.searchPlaceholder')}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />

                <MemoizedRefinementListItems items={items} onRefine={handleRefine} />
                {items && items.length === 0 && <RefinementEmptyList />}
            </StyledContainer>
        </>
    );
};

export default RefinementListStock;
