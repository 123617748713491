import { FC, memo } from 'react';
import SortBy from 'src/features/productList/components/SortBy/SortBy';
import { Flex } from 'src/shared/components/Flex';
import { useTranslation } from 'src/shared/utils/translation';
import { StyledWrapper, StyledCount } from './HitsHeader.styled';

interface HitsHeaderProps {
    totalHits: number;
}

const HitsHeader: FC<HitsHeaderProps> = ({ totalHits }) => {
    const { translate } = useTranslation();

    return (
        <StyledWrapper>
            <Flex alignItems="center" justifyContent="space-between">
                <StyledCount isVisible={totalHits > 0}>
                    {totalHits} {translate('plp.products')}
                </StyledCount>

                <SortBy />
            </Flex>
        </StyledWrapper>
    );
};

export default memo(HitsHeader);
